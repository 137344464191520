<template>
  <div class="casting-style">
    <titles :title="type == 0
      ? $t('text.t503')
      : type == 1
        ? $t('text.t522')
        : type == 2
          ? $t('text.t523')
          : ' '
      " />
    <div class="bigbox">
      <div class="bigbox-t1 bigbox-mb10">
        {{ $t("text.t504") }}
      </div>
      <div class="bb-inp bb-mb20">
        <van-radio-group v-model="isnew" direction="horizontal">
          <van-radio name="0" icon-size="16px" checked-color="#323232">{{
            $t("text.t505")
          }}</van-radio>
          <van-radio name="1" icon-size="16px" checked-color="#323232">{{
            $t("text.t506")
          }}</van-radio>
        </van-radio-group>
      </div>
      <template v-if="isnew == 1">
        <div class="bigbox-t1 bigbox-mb10">
          {{ $t("text.t507") }}
        </div>
        <div class="bb-inp bb-mb20">
          <div class="start-center bigbox-mb10">
            <div class="add-yz-left">
              <el-input v-model="old_contract" :placeholder="$t('text.t508')"></el-input>
            </div>
            <div class="add-yanzheng end-center">
              <div class="addbtns" @click="checkContract">
                {{ $t("text.t509") }}
              </div>
            </div>
          </div>
          <div class="add-status add-status1" v-if="ischecked">
            {{ $t("text.t510") }}
          </div>
        </div>
      </template>
      <template v-if="isnew == '0'">
        <div class="bigbox-t1 bigbox-mb10">
          {{ $t("text.t540") }}
        </div>
        <div class="bb-inp bb-mb20">
          <van-radio-group v-model="total_limit" direction="horizontal">
            <van-radio name="0" icon-size="16px" checked-color="#323232">{{ $t("text.t541") }}</van-radio>
            <van-radio name="1" icon-size="16px" checked-color="#323232">{{ $t("text.t542") }}</van-radio>
          </van-radio-group>
        </div>
        <div class="bb-inp bb-mb20" v-if="total_limit == '1'">
          <van-stepper :input-width="50" integer v-model="total_numbers" />
        </div>
      </template>
      <div class="bb-inp bb-mb20">
        <van-radio-group v-model="one_many" direction="horizontal">
          <van-radio name="0" icon-size="16px" checked-color="#323232">{{ $t("text.t543") }}</van-radio>
          <van-radio name="1" icon-size="16px" checked-color="#323232">{{ $t("text.t544") }}</van-radio>
        </van-radio-group>
      </div>
      <!-- 起始 -->
      <div v-for="(items, j) in casting_info_list" :key="j">
        <div class="bigbox-t1 bigbox-mb10 between-center" v-if="one_many == '1'">
          <div class="ad_bigtitle">{{ $t("text.t545") }}{{ j + 1 }}</div>
          <div class="end-center">
            <div v-if="casting_info_list.length > 1" class="cs_right center-center" @click="delCastingItem(j)">-</div>
            <div v-if="j == casting_info_list.length - 1" class="cs_right center-center" @click="addCastingItem">+</div>
          </div>
        </div>
        <div class="bigbox-t1 bigbox-mb10">{{ $t("text.t263") }}</div>
        <div class="bigbox-t2 bigbox-mb10">
          {{ $t("text.t264") }}
        </div>
        <div class="bb-mb20 start-center">
          <div @click="getUpImageIndex(j)">
            <van-uploader :max-count="1" :after-read="afterRead" v-model="items.fileList" @delete="deleteImg(j)" />
          </div>
        </div>
        <div class="bigbox-t1 bigbox-mb10">{{ $t("text.t525") }}</div>
        <div class="bigbox-t2 bigbox-mb10">
          {{ $t("text.t524") }}
        </div>
        <div class="bb-mb20 start-center">
          <div @click="getUpImageIndex(j)">
            <van-uploader :max-count="1" :after-read="afterRead1" v-model="items.fileList1" @delete="deleteImg1(j)"
              accept="*" />
          </div>
        </div>
        <div class="bigbox-t1 bigbox-mb10">{{ $t("text.t265") }}</div>
        <div class="bb-inp bb-mb20">
          <el-input v-model="items.symbol" :placeholder="$t('text.t272')"></el-input>
        </div>
        <div class="bigbox-t1 bigbox-mb10">{{ $t("text.t266") }}</div>
        <div class="bb-inp bb-mb20">
          <el-input v-model="items.name" :placeholder="$t('text.t273')"></el-input>
        </div>
        <div class="bigbox-t1 bigbox-mb10">{{ $t("text.t127") }}</div>
        <div class="bb-inp bb-mb20">
          <van-stepper :max="50" integer v-model="items.number" />
        </div>
        <div class="bigbox-t1 bigbox-mb10">{{ $t("text.t546") }}</div>
        <div class="bb-inp bb-mb20">
          <el-input type="textarea" v-model="items.description" :placeholder="$t('text.t547')"></el-input>
        </div>
        <div class="bigbox-t1 bigbox-mb10">{{ $t("text.t399") }}</div>
        <div class="bb-inp bb-mb20">
          <div class="attritem between-end" v-for="(item, index) in items.attributes" :key="index">
            <div class="left between-center">
              <div class="line1">
                <div class="l1title">{{ $t("text.t513") }}</div>
                <div class="l1inp">
                  <el-input v-model="item.trait_type"></el-input>
                </div>
              </div>
              <div class="line1">
                <div class="l1title">{{ $t("text.t514") }}</div>
                <div class="l1inp">
                  <el-input v-model="item.value">
                    <template #suffix>
                      <div class="sufbox column-center" v-if="items.attributes.length > 1">
                        <div class="suf center-center" @click="delAttr(index, j)">
                          -
                        </div>
                      </div>
                    </template>
                  </el-input>
                </div>
              </div>
            </div>
            <div v-if="index == items.attributes.length - 1" class="right center-center" @click="addAttr(j)">
              +
            </div>
          </div>
        </div>
        <div v-if="one_many == '1'" class="bb-mb20 add_casting_item_borderbottom"></div>
      </div>
      <!-- 结束 -->
      <div class="bigbox-t1 bigbox-mb10">{{ $t("text.t425") }}</div>
      <div class="bb-inp bb-mb20">
        <el-select :disabled="isnew == 1" v-model="features" multiple :placeholder="$t('text.t426')">
          <el-option v-for="item in featuresList" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </div>
      <div class="bigbox-t1 bigbox-mb10">{{ $t("text.t268") }}</div>
      <div class="bb-inp bb-mb20">
        <el-input :disabled="isnew == 1" v-model="names" :placeholder="$t('text.t269')"></el-input>
      </div>
      <div class="bigbox-t1 bigbox-mb10">{{ $t("text.t390") }}</div>
      <div class="bb-inp bb-mb20">
        <el-input :disabled="isnew == 1" type="textarea" v-model="zzzjieshao" :placeholder="$t('text.t515')"></el-input>
      </div>
      <div class="bigbox-t1 bigbox-mb10">{{ $t("text.t516") }}</div>
      <div class="bb-inp bb-mb20">
        <el-input :disabled="isnew == 1" type="textarea" v-model="ppjieshao" :placeholder="$t('text.t517')"></el-input>
      </div>
      <div class="bigbox-t1 bigbox-mb10">{{ $t("text.t511") }}</div>
      <div class="bb-inp bb-mb20">
        <el-input :disabled="isnew == 1" type="textarea" v-model="describe" :placeholder="$t('text.t512')"></el-input>
      </div>
      <div class="bigbox-t1 bigbox-mb10">{{ $t("text.t518") }}</div>
      <div class="bb-inp bb-mb20">
        <el-input :disabled="isnew == 1" type="textarea" v-model="guszcp" :placeholder="$t('text.t519')"></el-input>
      </div>
      <div class="bigbox-t1 bigbox-mb10">{{ $t("text.t270") }}</div>
      <div class="bb-inp bb-mb20">
        <el-select v-model="standard" :disabled="isnew == 1" :placeholder="$t('text.t271')">
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </div>
      <div class="bigbox-t1 bigbox-mb10">{{ $t("text.t275") }}</div>
      <div class="bb-inp bb-mb20">
        <el-input :disabled="isnew == 1" v-model="cost" type="number" :placeholder="`${$t('text.t276')},${config.zz_min}%-${config.zz_max
          }%`"></el-input>
      </div>
      <div class="bb-btn">
        <van-button @click="submit" :loading="isloading" block color="#323232" :loading-text="$t('text.t67')">{{
          $t("text.t277") }}</van-button>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import titles from "@/components/titles/index.vue";
export default {
  components: {
    titles,
  },
  data() {
    return {
      isnew: "0",
      old_contract: "",
      fileList: [], //d
      short_name: "", //d
      full_name: "", //d
      describe: "",
      names: "",
      standard: "",
      cost: "",
      numbers: "", //d
      options: [
        {
          value: "0",
          label: "ERC-721",
        },
        {
          value: "1",
          label: "ERC-1155",
        },
      ],
      featuresList: [],
      upimg: "", //d
      isloading: false,
      tmsg: "",
      attributes: [
        {
          trait_type: "",
          value: "",
        },
      ], //d
      features: [],
      zzzjieshao: "",
      ppjieshao: "",
      guszcp: "",
      type: "",
      contract_status: "",
      ischecked: false,
      fileList1: [], //d
      animation_url: '', //d
      total_limit: '0',
      total_numbers: 1,
      one_many: '0',
      casting_info_list: [
        {
          fileList: [],
          fileList1: [],
          image: "",
          animation_url: '',
          symbol: '',
          name: '',
          number: '',
          description: '',
          attributes: [
            {
              trait_type: "",
              value: ""
            }
          ]
        }
      ],
      upImageIndex: ''
    };
  },
  computed: {
    ...mapState(["language", "chainId", "config"]),
  },
  watch:{
    one_many(val,old){
      if(val == '0'){
        this.casting_info_list = [this.casting_info_list.find((ele,i)=>i === 0)]
      }
    }
  },
  created() {
    this.type = this.$route.query.type;
    this.getSortList();
  },
  mounted() { },
  methods: {
    delCastingItem(j) {
      if (this.casting_info_list.length <= 1) {
        return;
      }
      this.casting_info_list.splice(j, 1);
      console.log(this.casting_info_list)
    },
    addCastingItem() {
      this.casting_info_list.push(
        {
          fileList: [],
          fileList1: [],
          image: "",
          animation_url: '',
          symbol: '',
          name: '',
          number: '',
          description: '',
          attributes: [
            {
              trait_type: "",
              value: ""
            }
          ]
        }
      )
    },
    getUpImageIndex(e) {
      this.upImageIndex = e
    },
    getSortList() {
      this.$http
        .get("/core/classificationList", {
          page: 1,
          limit: 100,
          language: this.language,
        })
        .then((res) => {
          this.featuresList = res.data.data.map((ele) => {
            return {
              value: ele.id,
              label: this.language == 1 ? ele.title : ele.etitle,
            };
          });
        });
    },
    async submit() {
      if (this.isnew == 1) {
        if(!this.old_contract){
          this.$toast(this.$t("text.t508"));
          return;
        }
        if (!this.ischecked) {
          this.$toast(this.$t("text.t520"));
          return;
        }
      }
      for (let i = 0; i < this.casting_info_list.length; i++) {
        if(!this.casting_info_list[i].image){
          this.$toast(this.$t("text.t278"));
          return;
        }
        if(!this.casting_info_list[i].symbol){
          this.$toast(this.$t("text.t272"));
          return;
        }
        if(!this.casting_info_list[i].name){
          this.$toast(this.$t("text.t273"));
          return;
        }
        if(!this.casting_info_list[i].description){
          this.$toast(this.$t('text.t547'));
          return;
        }
        for (let j = 0; j < this.casting_info_list[i].attributes.length; j++) {
          if (!this.casting_info_list[i].attributes[j].trait_type || !this.casting_info_list[i].attributes[j].value) {
            this.$toast(this.$t("text.t521"));
            return;
          }
        }
      }
      if (!this.features.length) {
        this.$toast(this.$t("text.t426"));
        return;
      }
      if (!this.names) {
        this.$toast(this.$t("text.t269"));
        return;
      }
      if (!this.zzzjieshao) {
        this.$toast(this.$t("text.t515"));
        return;
      }
      if (!this.ppjieshao) {
        this.$toast(this.$t("text.t517"));
        return;
      }
      if (!this.describe) {
        this.$toast(this.$t("text.t512"));
        return;
      }
      if (!this.guszcp) {
        this.$toast(this.$t("text.t519"));
        return;
      }
      if (this.standard === "") {
        this.$toast(this.$t("text.t271"));
        return;
      }
      if (!this.cost) {
        this.$toast(this.$t("text.t276"));
        return;
      }
      if (
        Number(this.cost) < Number(this.config.zz_min) ||
        Number(this.cost) > Number(this.config.zz_max)
      ) {
        this.$toast(
          `${this.$t("text.t279")}${this.config.zz_min}~${this.config.zz_max}`
        );
        return;
      }
      this.isloading = true;
      try {
        const res = await this.createOrder();
        const { parameterjson, orderno } = res.data;
        const arg = JSON.parse(parameterjson)
        this.createNft(
          arg.name,
          arg.symbol,
          arg.tokenuri,
          arg.amount,
          arg.amountArr,
          Number(arg.createFee),
          Number(arg.totalLimit),
          orderno
        );
      } catch (error) {
        console.log(error);
        this.isloading = false;
      }
    },
    async createOrder() {
      let info_list = this.casting_info_list.map(ele=>({
        image:ele.image,
        animation_url:ele.animation_url,
        symbol:ele.symbol,
        name:ele.name,
        number:ele.number,
        description:ele.description,
        attributes:ele.attributes
      }))
      return await this.$http.post("/casting/castingOrder", {
        content: this.describe,
        name: this.names,
        copyright: this.cost,
        type: this.standard,
        language: this.language,
        introduce: this.zzzjieshao,
        brand: this.ppjieshao,
        about: this.guszcp,
        classification_ids: this.features.join(),
        established: this.isnew,
        casting_type: this.type,
        import: this.old_contract,
        file_type: this.one_many,
        filejson: JSON.stringify(info_list),
        limit_type: this.total_limit,
        limit: this.total_limit == '1' ? this.total_numbers : 0
      });
    },
    createNft(name, symbol, tokenuri, amount, amountArr, createFee, totalLimit, orderno) {
      const user_address = this.$local.get("shop:account");
      const web3 = new this.Web3(ethereum);
      let contract_to;
      let abi_to;
      if (this.standard == 1) {
        contract_to =
          this.chainId == "0x2d20"
            ? this.config.omp_1155
            : this.chainId == "0x38"
              ? this.config.bsc_1155
              : this.chainId == "0x1"
                ? this.config.eth_cast_1155
                : this.chainId == "0x89"
                  ? this.config.pol_cast_1155
                  : "";
        abi_to = JSON.parse(this.config.abi_1155);
      } else {
        contract_to =
          this.chainId == "0x2d20"
            ? this.config.omp_721
            : this.chainId == "0x38"
              ? this.config.bsc_721
              : this.chainId == "0x1"
                ? this.config.eth_cast_721
                : this.chainId == "0x89"
                  ? this.config.pol_cast_721
                  : "";
        abi_to = JSON.parse(this.config.abi_721);
      }
      const nft_contract = new web3.eth.Contract(abi_to, contract_to, {
        from: user_address,
      });
      console.log(nft_contract);
      if (this.standard == 1) {
        if (this.isnew == 1) {
          nft_contract.methods
            .mintNFT1155(this.old_contract, amount, amountArr)
            .send()
            .on("transactionHash", async (hash) => {
              const rest = await this.sendHash(hash, orderno);
              this.tmsg = "";
              this.tmsg = rest.msg;
            })
            .on("receipt", (receipt) => {
              console.log(receipt);
              this.isloading = false;
              this.$toast(this.tmsg);
            })
            .on("error", (error, receipt) => {
              console.log(error, receipt);
              this.isloading = false;
            });
        } else {
          nft_contract.methods
            .createNFT1155WithTotalLimit(name, symbol, tokenuri, amount, amountArr, createFee, totalLimit)
            .send()
            .on("transactionHash", async (hash) => {
              const rest = await this.sendHash(hash, orderno);
              this.tmsg = "";
              this.tmsg = rest.msg;
            })
            .on("receipt", (receipt) => {
              console.log(receipt);
              this.isloading = false;
              this.$toast(this.tmsg);
            })
            .on("error", (error, receipt) => {
              console.log(error, receipt);
              this.isloading = false;
            });
        }
      } else {
        if (this.isnew == 1) {
          nft_contract.methods
            .mintNFT721(this.old_contract, amount)
            .send()
            .on("transactionHash", async (hash) => {
              const rest = await this.sendHash(hash, orderno);
              this.tmsg = "";
              this.tmsg = rest.msg;
            })
            .on("receipt", (receipt) => {
              console.log(receipt);
              this.isloading = false;
              this.$toast(this.tmsg);
            })
            .on("error", (error, receipt) => {
              console.log(error, receipt);
              this.isloading = false;
            });
        } else {
          console.log(nft_contract.methods
            .createNFT721WithTotalLimit(name, symbol, tokenuri, amount, createFee, totalLimit))
          nft_contract.methods
            .createNFT721WithTotalLimit(name, symbol, tokenuri, amount, createFee, totalLimit)
            .send()
            .on("transactionHash", async (hash) => {
              const rest = await this.sendHash(hash, orderno);
              this.tmsg = "";
              this.tmsg = rest.msg;
            })
            .on("receipt", (receipt) => {
              console.log(receipt);
              this.isloading = false;
              this.$toast(this.tmsg);
            })
            .on("error", (error, receipt) => {
              console.log(error, receipt);
              this.isloading = false;
            });
        }
      }
    },
    async sendHash(hash, orderno) {
      const arg = {
        hash,
        orderno,
        language: this.language,
      };
      return await this.$http.post("/casting/castingHash", arg);
    },
    afterRead(file) {
      file.status = "uploading";
      file.message = this.$t("text.t74");
      let ff = new FormData();
      ff.append("file", file.file);
      this.$http
        .upload("/common/upload", ff)
        .then((res) => {
          this.casting_info_list[this.upImageIndex]['image'] = res.data.url;
          setTimeout(() => {
            file.status = "done";
            file.message = this.$t("text.t75");
          }, 1000);
        })
        .catch(() => {
          setTimeout(() => {
            file.status = "failed";
            file.message = this.$t("text.t76");
          }, 1000);
        });
    },
    deleteImg(e) {
      console.log(e)
      this.casting_info_list[e]['image'] = "";
    },
    afterRead1(file) {
      file.status = "uploading";
      file.message = this.$t("text.t74");
      let ff = new FormData();
      ff.append("file", file.file);
      this.$http
        .upload("/common/upload", ff)
        .then((res) => {
          this.casting_info_list[this.upImageIndex]['animation_url'] = res.data.url;
          setTimeout(() => {
            file.status = "done";
            file.message = this.$t("text.t75");
          }, 1000);
        })
        .catch(() => {
          setTimeout(() => {
            file.status = "failed";
            file.message = this.$t("text.t76");
          }, 1000);
        });
    },
    deleteImg1(e) {
      console.log(e)
      this.casting_info_list[e]['animation_url'] = "";
    },
    addAttr(e) {
      this.casting_info_list[e]['attributes'].push({
        trait_type: "",
        value: "",
      });
      console.log(this.casting_info_list[e]['attributes']);
    },
    delAttr(i, e) {
      if (this.casting_info_list[e]['attributes'].length <= 1) {
        return;
      }
      this.casting_info_list[e]['attributes'].splice(i, 1);
      console.log(this.casting_info_list[e]['attributes']);
    },
    checkContract() {
      if (!this.old_contract) {
        this.$toast(this.$t("text.t508"));
        return;
      }
      this.ischecked = false;
      this.$http
        .post("/casting/importCon", {
          import: this.old_contract,
          casting_type: this.type,
          language: this.language,
        })
        .then((res) => {
          this.ischecked = true;
          this.standard = res.data.type;
          this.features = res.data.classification_ids.split(',').map(ele=>Number(ele))
          this.names = res.data.name
          this.zzzjieshao = res.data.introduce
          this.ppjieshao = res.data.brand
          this.describe = res.data.content
          this.guszcp = res.data.about
          this.cost = res.data.copyright
        });
    },
  },
};
</script>

<style lang="less">
.casting-style {
  .bigbox {
    width: 321px;
    padding: 41px 0;
    margin: auto;

    .box1 {
      text-align: center;
      margin-bottom: 25px;
      font-size: 18px;
      font-weight: bold;
      color: #323232;
    }

    .bigbox-t1 {
      font-size: 15px;
      font-weight: bold;
      color: #323232;
    }

    .ad_bigtitle {
      font-size: 18px;
      color: #515DF7;
    }

    .cs_right {
      width: 30px;
      height: 30px;
      font-size: 20px;
      font-weight: bold;
      color: #515DF7;
      background: #ffffff;
      border: 2px solid #515DF7;
      border-radius: 5px;
      margin-left: 8px;
      box-sizing: border-box;
    }

    .bigbox-t2 {
      font-size: 12px;
      font-weight: bold;
      color: #323232;
    }

    .bigbox-mb10 {
      margin-bottom: 10px;
    }

    .bb-mb20 {
      margin-bottom: 20px;
    }

    .add_casting_item_borderbottom {
      border-bottom: 1px solid #f1f1f1;
    }

    .bb-inp {
      width: 100%;

      .el-select {
        display: block;
      }
    }

    .bb-btn {
      width: 300px;
      margin: auto;
      padding-top: 28px;

      .van-button {
        border-radius: 5px;
      }
    }

    .attritem {
      margin-bottom: 15px;

      .left {
        flex: 1;

        .line1 {
          width: 49%;

          .l1title {
            font-size: 12px;
            font-weight: bold;
            color: #323232;
            margin-bottom: 10px;
          }

          .l1inp {
            width: 100%;

            .sufbox {
              height: 100%;
              padding-right: 8px;
            }

            .suf {
              width: 15px;
              height: 15px;
              background: #ffffff;
              border: 1px solid #323232;
              border-radius: 8px;
              font-size: 16px;
              font-weight: bold;
              color: #323232;
            }
          }
        }
      }

      .right {
        width: 30px;
        height: 30px;
        font-size: 15px;
        font-weight: bold;
        color: #323232;
        background: #ffffff;
        border: 1px solid #323232;
        border-radius: 5px;
        margin-left: 8px;
        box-sizing: border-box;
      }
    }

    .attritem:last-of-type {
      margin-bottom: 0;
    }

    .add-yanzheng {
      flex: auto;
      padding-left: 10px;

      .addbtns {
        background: #323232;
        border-radius: 20px;
        padding: 12px 20px;
        font-size: 12px;
        font-weight: bold;
        color: #ffffff;
      }
    }

    .add-yz-left {
      width: 220px;
    }

    .add-status {
      font-size: 12px;
      font-weight: 500;
    }

    .add-status1 {
      color: #4f6ed2;
    }

    .add-status2 {
      color: #d24f4f;
    }
  }
}</style>